import {formatDate} from './custom-date-adapter';

/**
 * CommonJS 'module.exports' is wrapped as 'default' in ESModule.
 * @param importPromise
 */
export async function normalizeImport<T>(importPromise: Promise<T>): Promise<T> {
  return importPromise.then((m: unknown) => (m as {default: T})?.default || (m as T));
}

export function urlWithoutParams(url: string): string {
  return url ? url.split('?')[0] || '' : '';
}

export function upperCaseFirstLetter(str: string): string {
  if (str?.length) {
    const separator = ' ';
    const strArr = str?.split(separator);
    return strArr?.map(i => `${i[0]?.toUpperCase()}${i?.substring(1)?.toLowerCase()}`)?.join(separator);
  } else {
    return null;
  }
}

export function stringToStringArray(value: string): string[] {
  return value?.length
    ? value
        ?.split(',')
        .filter(Boolean)
        .map(i =>
          i
            ?.split('')
            ?.filter(j => j !== ' ')
            ?.join('')
        )
    : [];
}

export function convertUsername(name: string): string {
  let converted;
  if (typeof name === 'string' && name?.startsWith('CN=')) {
    converted = name.replace('CN=', '');
    const regexp = new RegExp(/\/(.?)*/g);
    converted = converted.replace(regexp, '');
  } else {
    converted = name;
  }
  return converted;
}

export function isOption(value: any): boolean {
  return value && typeof value === 'object' && (hasOwnProperty(value, 'label') || hasOwnProperty(value, 'value'));
}

export function hasOwnProperty(obj: unknown, prop: string): boolean {
  return !!obj && !!prop?.length && Object.prototype.hasOwnProperty.call(obj, prop);
}

export function isOptionArray(value: any): boolean {
  return value && Array.isArray(value) && value.length && value[0] && isOption(value[0]);
}

export function columnValueFormatter(field: string, value: any, type?: string): any {
  switch (true) {
    case isOptionArray(value):
      return value.map(i => i?.label)?.join(', ');
    case field === 'author' && value?.label:
      return convertUsername(value.label);
    case isOption(value):
      return value.label;
    case type === 'date' || ['created', 'modified'].includes(field):
      return formatDate(value);
    case Array.isArray(value):
      return value.join(', ');
    default:
      return value;
  }
}

export function copyStringToClipboard(str: string): Promise<void> {
  // ClipboardItem doesn't support by Firefox browser. Using deprecated execCommand to copy text.
  // https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem
  // TODO remove workaround after Firefox update with ClipboardItem support.
  if (navigator.userAgent.toLowerCase().includes('firefox')) {
    const input = document.createElement('input');
    input.setAttribute('value', str);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    return Promise.resolve();
  }

  // ClipboardItem supported in other browsers
  const type = 'text/plain';
  const blob = new Blob([str], {type});
  const item = new ClipboardItem({[type]: blob});
  return navigator.clipboard.write([item]);
}

export function getAttachmentIconName(fileType: string): string {
  const isMatch = (regExp: RegExp) => !!fileType.match(regExp)?.length;

  if (fileType) {
    switch (true) {
      case isMatch(/pdf$/g):
        return 'pdf';
      case isMatch(/png$|jpg$|jpeg$/g):
        return 'image';
      case isMatch(/doc$|docm$|docx$/g):
        return 'word';
      case isMatch(/ppt$|ppsx$|pptm$|pptx$/g):
        return 'powerpoint';
      case isMatch(/xlsx$|xlsm$|xltx$|xltm$/g):
        return 'excel';
      default:
        return 'docs';
    }
  }

  return 'docs';
}

export function attachmentsFormData(attachments: File[]): FormData {
  const formData = new FormData();
  formData.append('numAttachments', attachments.length.toString());
  attachments.forEach((i, x) => formData.set(`attachment-${x}`, i));
  return formData;
}

export function getEventTargetFiles(target: EventTarget): File[] {
  if ('files' in target && target.files) {
    return Object.values(target.files) as File[];
  }
  return [];
}
