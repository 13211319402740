import {Pipe, PipeTransform} from '@angular/core';
import {IOption} from '../interfaces';

@Pipe({
  name: 'optionLabel',
  standalone: true
})
export class OptionLabelPipe implements PipeTransform {
  transform(option: IOption | string): string {
    const label = typeof option === 'object' ? option.label : option;
    return label || 'MISSING_LABEL';
  }
}
