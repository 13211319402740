import {NativeDateAdapter} from '@angular/material/core';

const DATE_FORMAT_OPTIONS: Readonly<object> = Object.freeze({
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
});

export class CustomDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string) {
    super(matDateLocale);
  }

  /** Datepicker methods start */

  override format(date: Date | string): string {
    const parsed = getParsedDate(date);
    return getFormattedDate(parsed);
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }

  getHour(date: Date): number | string {
    return date ? new Date(date).getHours() : '';
  }

  getMinute(date: Date): number | string {
    return date ? new Date(date).getMinutes() : '';
  }

  getSecond(date: Date): number | string {
    return date ? new Date(date).getSeconds() : '';
  }

  copyTime(date: Date): number | string {
    return date ? new Date(date).getTime() : '';
  }

  isSameTime(): boolean {
    return false;
  }

  /** Datepicker methods end */

  /** Timepicker methods start */

  setHour(date: Date, value: number): void {
    if (date) {
      date.setHours(value);
    }
  }

  setMinute(date: Date, value: number): void {
    if (date) {
      date.setMinutes(value);
    }
  }

  setSecond(date: Date, value: number): void {
    if (date) {
      date.setSeconds(value);
    }
  }

  /** Timepicker methods end */
}

export function invertedDate(date: string): string {
  if (date) {
    const formatted = getFormattedDate(new Date(date));
    return formatted.split('.').reverse().join('-');
  }
  return '';
}

export function addDays(date: Date | string, days?: number): Date {
  const parsed = getParsedDate(date);
  if (days) {
    parsed.setDate(parsed.getDate() + days);
  }
  return parsed;
}

export function timeToDate(time?: string): Date {
  const parsed = time?.length && time?.includes(':') ? time.split(':').map(i => parseInt(i)) : [];
  const hours = typeof parsed[0] === 'number' ? parsed[0] : 0;
  const minutes = typeof parsed[1] === 'number' ? parsed[1] : 0;
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

export function formatDate(date?: Date | string, isFullDate?: boolean): string {
  if (date) {
    const parsed = getParsedDate(date);
    const formatOptions = isFullDate ? {...DATE_FORMAT_OPTIONS, hour: '2-digit', minute: '2-digit'} : null;
    return getFormattedDate(parsed, formatOptions);
  }
  return '';
}

function getParsedDate(date: Date | string): Date {
  return typeof date === 'object' ? date : new Date(date);
}

function getFormattedDate(date: Date, dateFormatOptions?: object | null): string {
  return new Intl.DateTimeFormat('no', dateFormatOptions || DATE_FORMAT_OPTIONS).format(date);
}
