export const fieldTranslations = {
  'address.zip': 'COMMON.ZIP-CODE',
  'address.city': 'COMMON.CITY',
  'address.country.name': 'COMMON.COUNTRY',
  'from.label': 'COMMON.AUTHOR',
  'person.label': 'COMMON.NAME',
  'assignee.user.label': 'STATUSES.ASSIGNEE',
  'verifier.user.label': 'STATUSES.VERIFIER',
  'approver.user.label': 'STATUSES.APPROVER',
  'workflow.assignee.user.label': 'STATUSES.ASSIGNEE',
  'workflow.verifier.user.label': 'STATUSES.VERIFIER',
  'workflow.approver.user.label': 'STATUSES.APPROVER',
  'contact.company.label': 'COMMON.COMPANY',
  'company.label': 'COMMON.COMPANY',
  'organization.label': 'SHARED.SIDENAV.ORGANIZATION.TITLE',
  'contact.label': 'COMMON.CONTACT',
  'ourContact.label': 'COMMON.OUR-CONTACT',
  'projManager.label': 'COMMON.PROJECT-MANAGER',
  'name.label': 'COMMON.EMPLOYEE',
  'project.label': 'COMMON.PROJECT',
  'modifiedBy.label': 'COMMON.MODIFIED-BY',
  'lastModifiedBy.user.displayName': 'COMMON.MODIFIED-BY',
  'fileSystemInfo.lastModifiedDateTime': 'COMMON.MODIFIED',
  'superior.label': 'COMMON.SUPERIOR',
  'body.subject': 'COMMON.SUBJECT',
  'book.subject': 'QUALITY.DOCUMENTS.BOOK',
  author: 'COMMON.AUTHOR',
  subject: 'COMMON.SUBJECT',
  groups: 'COMMON.GROUPS',
  title: 'COMMON.SUBJECT',
  date: 'COMMON.DATE',
  dateLast: 'SHARED.TABLE.FIELDS.LAST-DATE',
  dueDate: 'COMMON.DUE-DATE',
  expire: 'COMMON.EXPIRE',
  startDate: 'COMMON.START-DATE',
  dateExpire: 'COMMON.DATE-EXPIRE',
  pubDate: 'COMMON.PUBLISH-DATE',
  status: 'COMMON.STATUS',
  categories: 'COMMON.CATEGORIES',
  attachment: 'COMMON.ATTACHMENTS',
  created: 'COMMON.CREATED',
  modified: 'COMMON.MODIFIED',
  fullName: 'COMMON.NAME',
  officePhoneNumber: 'COMMON.OFFICE-PHONE',
  cellPhoneNumber: 'COMMON.CELL-PHONE',
  phoneNumber: 'COMMON.PHONE',
  mailAddress: 'COMMON.E-MAIL',
  email: 'COMMON.E-MAIL',
  projName: 'COMMON.SUBJECT',
  projNum: 'COMMON.PROJECT-NUMBER',
  projType: 'COMMON.PROJECT-TYPE',
  riskSum: 'QUALITY.RISKS.RISK',
  license: 'SHARED.TABLE.FIELDS.LICENSE',
  pageType: 'COMMON.TYPE',
  docType: 'COMMON.TYPE',
  type: 'COMMON.TYPE',
  sale: 'DOCUMENTS.SALE',
  purchase: 'DOCUMENTS.PURCHASE',
  empId: 'COMMON.EMPLOYEE-ID',
  position: 'COMMON.POSITION',
  lifetime: 'COMMON.LIFETIME',
  value: 'SETTINGS.VALUE',
  city: 'COMMON.CITY',
  votes: 'COMMON.VOTES',
  feedback: 'COMMON.FEEDBACK',
  from: 'COMMON.RELATION',
  companyName: 'COMPANIES.DETAILS.COMPANY-NAME',
  docId: 'COMMON.DOCUMENT-NUMBER',
  responsible: 'DOCUMENTS.RESPONSIBLE',
  department: 'QUALITY.INCIDENTS.CAUSAL-AREA',
  incidentType: 'COMMON.TYPE',
  deviationClass: 'COMMON.DEVIATION-CLASS',
  deviationType: 'COMMON.DEVIATION-TYPE',
  level: 'QUALITY.DOCUMENTS.LEVEL',
  name: 'COMMON.NAME',
  fileBased: 'QUALITY.DOCUMENTS.FILE-BASED',
  officeType: 'COMPANIES.DETAILS.OFFICE-TYPE',
  storeName: 'COMMON.MODULE',
  errorMessage: 'ERRORS.ERROR',

  // customFields
  new: 'COMMON.NEW',
  list: 'COMMON.LIST',

  // invalidControlsDirective
  body: 'COMMON.DESCRIPTION', // used common field with different labels
  firstName: 'CONTACTS.DETAILS.FIRST-NAME',
  lastName: 'CONTACTS.DETAILS.LAST-NAME',
  user: 'COMMON.USER',
  assignee: 'STATUSES.ASSIGNEE',
  riskType: 'QUALITY.RISKS.RISK-TYPES',
  operation: 'QUALITY.RISKS.WORK-OPERATION',
  risks: 'QUALITY.RISKS.TITLE',
  consequences: 'QUALITY.RISKS.CONSEQUENCES',
  actions: 'QUALITY.RISKS.ACTIONS',
  riskLevelBefore: 'QUALITY.RISKS.RISK-BEFORE-ACTIONS',
  riskLevelAfter: 'QUALITY.RISKS.RISK-AFTER-ACTIONS',
  proposal: 'SHARED.SIDENAV.INCIDENT-REPORTS.PREVENTION-SUGGESTIONS',
  contId: 'COMMON.CONTACT',
  template: 'COMMON.TEMPLATE',
  templateId: 'COMMON.OFFICE-TEMPLATE',
  company: 'COMMON.COMPANY',
  dateStart: 'COMMON.START-DATE',
  docDate: 'COMMON.DATE',
  projPlace: 'PROJECTS.DETAILS.PROJECT-PLACE',
  book: 'QUALITY.DOCUMENTS.BOOK',
  dataSets: 'COMMON.DATASETS',
  gender: 'SETTINGS.EMPLOYEE.GENDER',
  actionOnSiteBody: 'QUALITY.INCIDENTS.DIALOG.ACTIONS-TAKEN',
  resultBody: 'QUALITY.INCIDENTS.DETAILS.CORRECTIVE-ACTION',
  location: 'COMMON.LOCATION',
  sendgridId: 'CONTACTS.NEWSLETTER-TEMPLATES.SENDGRID-ID',
  what: 'QUALITY.INCIDENTS.DETAILS.WHAT-HAPPENED',
  why: 'QUALITY.INCIDENTS.DETAILS.MOST-PROBABLE-REASON',
  ourContact: 'COMMON.OUR-CONTACT'
};
