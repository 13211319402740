import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, map, merge, Observable, startWith} from 'rxjs';
import {MediaMatcher} from '@angular/cdk/layout';
import {INavigatorTarget} from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilitiesService {
  private readonly onLine: boolean;
  private readonly mobileQuery: MediaQueryList = this.media.matchMedia('(max-width: 800px)');
  private readonly layoutSubject = new BehaviorSubject<boolean>(this.isMobile);

  constructor(private media: MediaMatcher) {
    this.onLine = navigator.onLine;

    if (this.isSafari) {
      this.mobileQuery.addListener(e => this.layoutSubject.next(e.matches));
    } else {
      this.mobileQuery?.addEventListener('change', e => this.layoutSubject.next(e.matches));
    }
  }

  get isMobile(): boolean {
    return window?.innerWidth <= 800;
  }

  get isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public getConnection(): Observable<boolean> {
    const events = ['online', 'offline'].map(i => fromEvent(window, i));
    return merge(...events).pipe(
      startWith({target: {navigator: {onLine: this.onLine}}}),
      map((e: Event) => (e.target as INavigatorTarget).navigator.onLine)
    );
  }

  public getLayout(): Observable<boolean> {
    return this.layoutSubject.asObservable();
  }

  public getWindowResize(): Observable<number> {
    return fromEvent(window, 'resize').pipe(map((e: any) => e?.target?.innerWidth));
  }
}
